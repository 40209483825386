<template>
	<div id="adoptAgreement">
		<div class="el-content">
            <a-tabs>
                <a-tab-pane key="1" tab="基本设置">
                    <com-power-form form-type="check" :formData="[
                        {label:'是否展示标题',type:'radio',key:'adopt_list_title_open',value:'',dataType:'string',
                            options:[
                                {label:'是',value:'1'},
                                {label:'否',value:'0'},
                            ]
                        },
                        {label:'认养列表标题',type:'text',key:'adopt_list_title',value:'',dataType:'string',show:{key:'adopt_list_title_open',value:'1'}},
                        {label:'认养列表说明',type:'text',key:'adopt_list_desc',value:'',dataType:'string',show:{key:'adopt_list_title_open',value:'1'}},
                        {label:'字体颜色',type:'color',key:'adopt_list_text_color',value:'',dataType:'string',show:{key:'adopt_list_title_open',value:'1'}},
                        {label:'认养顶部封面(375*210)',type:'image',key:'adopt_list_banner',value:'',dataType:'string'},
                    ]"></com-power-form>
                </a-tab-pane>
                <a-tab-pane key="2" tab="语言包设置">
                    <a-row>
                        <a-col :span="8">
                            <div>
                                <a-image width="375px" :src="$util.getStaticSrc('admin/adopt-lug-1.png')"></a-image>
                            </div>
                            <div>
                                <a-image width="375px" :src="$util.getStaticSrc('admin/adopt-lug-2.png')"></a-image>
                            </div>

                        </a-col>
                        <a-col :span="12">
                            <a-form :label-col="{span:4}" :warpper-col="{span:16}">
                                <a-form-item label="认养农业 1">
                                    <a-input v-model:value="form.adopt_list_title"></a-input>
                                </a-form-item>
                                <a-form-item label="立即认养 2">
                                    <a-input v-model:value="form.adopt_button1"></a-input>
                                </a-form-item>
                                <a-form-item label="认养详情 1">
                                    <a-input v-model:value="form.adopt_title2"></a-input>
                                </a-form-item>
                                <a-form-item label="认养收获 2">
                                    <a-input v-model:value="form.adopt_label1"></a-input>
                                </a-form-item>
                                <a-form-item label="认养流程 3">
                                    <a-input v-model:value="form.adopt_label2"></a-input>
                                </a-form-item>
                                <a-form-item label="认养环境 4">
                                    <a-input v-model:value="form.adopt_label3"></a-input>
                                </a-form-item>
                                <a-form-item label="认养排名 5">
                                    <a-input v-model:value="form.adopt_label4"></a-input>
                                </a-form-item>
                                <a-form-item label="立即认养 6">
                                    <a-input v-model:value="form.adopt_button2"></a-input>
                                </a-form-item>
                                <a-form-item :wrapper-col="{offset:4}">
                                    <a-button type="primary" @click="saveAdoptLug">保存</a-button>
                                </a-form-item>
                            </a-form>
                        </a-col>
                    </a-row>
                </a-tab-pane>
            </a-tabs>

		</div>
	</div>
</template>

<script>
import comPowerForm from '@/components/form/com-power-form.vue'
import {reactive, toRefs} from "vue";
import setModel from "@/api/set";
import tool from "@/util/tool";
export default{
	components:{
		comPowerForm
	},
    setup(){
        // 第一页
        // adopt_title1 综合认养
        // adopt_button1 立即认养
        // 第二页
        // adopt_button2 立即认养
        // adopt_title2 认养详情
        // adopt_label1 认养收货
        // adopt_label2 认养流程
        // adopt_label3 认养环境
        // adopt_label4 认养排名
        let state = reactive({
            form:{
                adopt_list_title:"",
                adopt_title1:"",
                adopt_button1:"",
                adopt_button2:"",
                adopt_title2:"",
                adopt_label1:"",
                adopt_label2:"",
                adopt_label3:"",
                adopt_label4:"",
            }
        })

        setModel.getFarmSet(Object.keys(state.form)).then(res=>{
            state.form = tool.filterForm(Object.keys(state.form),res)
        })

        function saveAdoptLug(){
            setModel.setFarmSet(state.form)
        }

        return {
            ...toRefs(state),
            saveAdoptLug
        }
    }
}
</script>

<style>
</style>
